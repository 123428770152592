/*
  Order matters here. See the following for more details:

    https://tailwindcss.com/docs/extracting-components
    https://tailwindcss.com/docs/adding-new-utilities

*/

/* Tailwind */
@tailwind base; /* stylelint-disable-line at-rule-no-unknown */
@tailwind components; /* stylelint-disable-line at-rule-no-unknown */
@tailwind utilities; /* stylelint-disable-line at-rule-no-unknown */

/* Custom base - https://tailwindcss.com/docs/adding-base-styles#using-css */

/* TODO: Put these in their own file. It wasn't working because of specificity. */
* {
  max-width: none;
}

body {
  -webkit-font-smoothing: antialiased;
  min-width: theme("screens.xs");
}

a {
  @apply text-blue-560;
}

header,
footer {
  a {
    @apply text-gray-900;
  }
}

code,
kbd,
samp,
pre {
  @apply font-ultramarine;
}

.w-content {
  max-width: 1440px;

  @apply m-auto;
}

.w-block {
  max-width: 1400px;
  padding: 3.5rem 1.4rem;

  @apply m-auto;
}

@media (max-width: 480px) {
  .w-block {
    padding: 2rem 1rem;
  }
}

///redesign
@layer base {
  .gap-primary {
    @apply gap-[16px] md:gap-[32px];
  }

  .gap-x-primary {
    @apply gap-x-[16px] md:gap-x-[32px];
  }

  .padding-l-primary {
    @apply pl-primary-xs md:pl-primary-md;
  }

  .padding-t-primary {
    @apply pt-primary-md;
  }

  .padding-b-primary {
    @apply pb-primary-md;
  }

  .padding-x-primary {
    @apply px-primary-xs md:px-primary-md;
  }

  .padding-x-primary-xl {
    @apply px-primary-md md:px-primary-lg xl:px-primary-xl;
  }

  .padding-y-primary {
    @apply py-primary-md;
  }

  .margin-r-primary {
    @apply mr-primary-xs md:mr-primary-md;
  }

  .margin-t-primary-xs {
    @apply mt-primary-xs;
  }

  .margin-t-primary {
    @apply mt-primary-xs md:mt-primary-md;
  }

  .margin-t-primary-lg {
    @apply mt-primary md:mt-primary-lg;
  }

  .margin-t-primary-xl {
    @apply mt-primary lg:mt-primary-lg;
  }

  .margin-b-primary-lg {
    @apply mb-primary-md md:mb-primary-lg lg:mb-primary-lg;
  }

  .margin-b-primary {
    @apply mb-primary-xs md:mb-primary-md;
  }

  .margin-y-primary {
    @apply my-primary-xs md:my-primary-md;
  }

  .margin-y-primary-xl {
    @apply my-primary-md md:my-primary-lg lg:my-primary-xl;
  }
}

.heading-1 {
  @apply text-heading-2 md:text-heading-1 leading-heading-2 md:leading-heading-1;
}

.heading-2 {
  @apply text-heading-3 md:text-heading-2 leading-heading-3 md:leading-heading-2;
}

.heading-3 {
  @apply text-heading-3 leading-heading-3;
}

.img-radius {
  @apply rounded-lg;
}

/////redesign 2
.arrowIcon {
  stroke-width: 3;
}

p {
  letter-spacing: -0.1px;
}

.slider-item-0-1,
.slider-item-1-2,
.slider-item-2-0 {
  margin-left: 16em;
  margin-top: 2em;
}

.slider-item-1-0,
.slider-item-2-1,
.slider-item-0-2 {
  margin-left: -16em;
  margin-top: 2em;
}

@media (max-width: 600px) {
  .slider-item-0-1,
  .slider-item-1-2,
  .slider-item-2-0 {
    margin-left: 6em;
    margin-top: 1.5em;
  }

  .slider-item-1-0,
  .slider-item-2-1,
  .slider-item-0-2 {
    margin-left: -6em;
    margin-top: 1.5em;
  }
}
